import { getMetadata } from 'modules/utils/metadata';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default () => {
	const [metadata, setMetadata] = useState({});
	const [loaded, setLoaded] = useState(false);

	const { pathname } = useLocation();

	const fetchMetadata = async () => {
		const metadata = await getMetadata();

		setMetadata(metadata);
		setLoaded(true);
	};

	useEffect(() => {
		fetchMetadata();
	}, []);

	if (!loaded) {
		return;
	}

	const collectionId = pathname.split('/')[2];
	const collectionMetadata = metadata?.[collectionId];

	return (
		<div className='sticky top-0 left-0 px-5 pt-10 sm:px-5 mix-blend-difference red:mix-blend-normal light:text-white overflow-y-hidden z-50'>
			<div className='relative animate-swipe-up-initial translate-y-[200%] opacity-0 w-full flex flex-row justify-between uppercase text-xl sm:text-4xl font-bld'>
				{pathname === '/' ? (
					<Link to='who-am-i'>Who am I?</Link>
				) : (
					<Link to='/'>Home</Link>
				)}
				{pathname.includes('/photo') ? (
					<span className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-nowrap'>
						{collectionMetadata.name}
					</span>
				) : (
					<Link
						to='/booking'
						className='absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%] text-nowrap'>
						Book Me
					</Link>
				)}
				<Link to='contact'>Contact</Link>
			</div>
		</div>
	);
};
