import BeforeEdit1 from 'assets/images/bookMe/DSC00817-Enhanced-NR-2.jpg';
import AfterEdit1 from 'assets/images/bookMe/DSC00817-Enhanced-NR.jpg';
import { useEffect, useRef, useState } from 'react';
import PortraitPhoto from 'assets/images/hawaii-690.jpg';
import LazyLoadedImage from 'Components/shared/LazyLoadedImage';

export default () => {
	const theRef = useRef();
	const afterRef = useRef();
	const [scroll, setScroll] = useState(0);

	useEffect(() => {
		theRef.current.addEventListener('input', (e) => {
			const val = parseInt(e.target.value);
			console.log(val);

			afterRef.current.style.width = `${val}%`;
		});

		document.querySelector('main').addEventListener('scroll', (e) => {
			setScroll(e.target.scrollTop);
		});
	}, []);

	return (
		<div className='flex flex-col gap-20 w-full h-full p-10 font-standard'>
			<div
				// style={{
				// 	filter: `blur(${scroll / 20}px)`,
				// }}
				className={`flex flex-col gap-2 sm:w-1/2  place-items-center`}>
				<LazyLoadedImage
					src={PortraitPhoto}
					alt='Me'
					width={6000}
					height={4000}
					style={{
						filter: `blur(${scroll})`,
					}}
					className={`brightness-[150%] w-[500px]`}
				/>
				<p className='text-lg sm:text-xl text-center'>
					My name is Caden Marinozzi and I'm your go-to photographer
					for capturing stunning moments. Whether it's automotive,
					portrait, event, or any other type of photography, I create
					compelling images that tell a story and evoke emotions.
					Based in Mill Valley, my work spans the entire Bay Area.
				</p>
			</div>
			<div
				// style={{
				// 	filter: `blur(${(scroll - 600) / 20}px)`,
				// }}
				className='flex flex-row gap-10 w-full justify-center place-items-center'>
				{/* <div className='flex flex-col gap-2 place-items-center'>
					<img src={BeforeEdit1} className='w-[400px]' />
					<img src={AfterEdit1} className='w-[400px]' />
				</div> */}
				<div className=' flex-col place-items-center gap-2 hidden sm:block'>
					<div className='relative w-[400px] h-[200px] overflow-clip flex flex-row place-items-center'>
						<img src={BeforeEdit1} />
						<div
							ref={afterRef}
							style={{
								backgroundImage: `url(${AfterEdit1})`,
							}}
							className='h-full w-1/2 [background-size:cover] absolute top-0 overflow-hidden bottom-0 border-r-8 border-neutral shadow-md shadow-black'
						/>
						<input
							ref={theRef}
							type='range'
							min={0}
							max={100}
							className='cursor-grab active:cursor-grabbing absolute left-0 top-1/2 translate-y-[-50%] opacity-0 h-full w-full [-webkit-appearance:none] [-moz-appearance:none] bg-transparent'
						/>
					</div>
					<span className='text-[rgb(139,139,145)]'>
						Drag to reveal edit
					</span>
				</div>
				<div className='flex flex-col gap-2 sm:place-self-end w-full sm:w-1/2'>
					<ol className='mx-5 list-decimal text-lg sm:text-xl flex flex-col gap-5'>
						<li className='sm:place-self-end sm:w-2/3'>
							<span className='text-lg sm:text-xl font-bold'>
								Planning:
							</span>
							<br />
							You describe your subject and vision, and we work
							together to schedule a date and time that suits you
							best. Most shoots take place at or near sunset,
							between 4-6 PM, to capture the best natural light.
							Together, we'll decide on the perfect location.
						</li>
						<li className='sm:place-self-start sm:w-2/3'>
							<span className='text-lg sm:text-xl font-bold'>
								Shooting:
							</span>{' '}
							<br />
							I'll either come to your location or host you at
							mine for a tailored photoshoot that matches your
							vision.
						</li>
						<li className='sm:place-self-end sm:w-2/3'>
							<span className='text-lg sm:text-xl font-bold'>
								Editing:
							</span>{' '}
							<br />
							After the shoot, I'll spend up to a week editing
							your photos, with most projects completed within two
							days.
						</li>
						<li className='sm:place-self-start sm:w-2/3'>
							<span className='text-lg sm:text-xl font-bold'>
								Delivery:
							</span>{' '}
							<br />
							Once editing is complete, I'll send you watermarked
							proofs. Upon your approval and payment, you'll
							receive the final, high-resolution images. If
							adjustments are needed after delivery, re-edits are
							available at standard editing rates.
						</li>
					</ol>
				</div>
			</div>
			<div className='flex flex-col sm:flex-row gap-10 w-full'>
				<div className='flex flex-col gap-2'>
					<span className='text-3xl font-bold sm:place-self-start place-self-center '>
						Pricing
					</span>
					<span>All prices negotiable</span>
				</div>
				<ul className='mx-5 text-lg sm:text-xl flex flex-col gap-5'>
					<li>
						<span className='font-bold text-lg md:text-xl'>
							Automotive Rollers Shooting:
							<br />
						</span>
						<span className='font-extrabold text-md md:text-lg'>
							$50/hr
						</span>{' '}
						(Minimum charge 1/2 hour)
					</li>
					<li>
						<span className='font-bold text-lg md:text-xl'>
							All Other Stills Shooting:
							<br />
						</span>
						<span className='font-extrabold text-md md:text-lg'>
							$30/hr
						</span>{' '}
						(Minimum charge 1 hour)
					</li>
					<li>
						<span className='font-bold text-lg md:text-xl'>
							Editing (Required fee, applies to re-edits as well):
							<br />
						</span>
						<span className='font-extrabold text-md md:text-lg'>
							$5/photo
						</span>{' '}
						for first 10 <br />
						<span className='font-extrabold text-md md:text-lg'>
							$3/photo
						</span>{' '}
						for additional photos
					</li>
					<li>
						<span className='font-bold text-lg md:text-xl'>
							Travel Fee:
							<br />
						</span>
						<span className='font-extrabold text-md md:text-lg'>
							$1/mile
						</span>{' '}
						past 20 miles from Mill Valley
					</li>
					<li>
						<span className='font-bold text-lg md:text-xl'>
							Raws:
							<br />
						</span>
						<span className='font-extrabold text-md md:text-lg'>
							$20
						</span>{' '}
						for entire photo set
					</li>
				</ul>
				<div className='group place-self-center sm:place-self-start sm:place-items-start place-items-center flex flex-col gap-2 md:gap-5 m-2 md:m-5'>
					<a
						href='mailto:cadenmarinozzi@gmail.com'
						className='text-3xl lg:text-8xl uppercase font-bld'>
						SEND ME AN EMAIL AND LET'S GET SHOOTING
					</a>
					<span className='text-2xl sm:opacity-0 transition-all group-hover:opacity-100'>
						cadenmarinozzi@gmail.com
					</span>
				</div>
			</div>
		</div>
	);
};
